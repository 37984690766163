<template>
  <!-- prettier-ignore -->
  <div>
    <search-condition searchable :loading="searching" @do-search="search">
      <n-field-selection v-model="condition.operatorId" selection-name="admin.operator" label="operator" all-option disabled />
      <n-field-enum v-model="condition.status" enum-name="AdminOperatorNoticeStatus" label="status" all-option />
      <n-field-datetime v-model="condition.from" label="from" />
      <n-field-datetime v-model="condition.to" label="to" />
      <n-field-enum v-model="condition.domain" enum-name="AdminNoticeDomain" label="domain" all-option />
      <n-field-select v-model="condition.type" :options="noticeTypes" label="type" all-option />
      <n-field-enum v-model="condition.level" enum-name="AdminNoticeLevel" label="level" all-option />
    </search-condition>
    <n-table :total="total" :page-no="pageNo" :page-size="pageSize" :searched="searched" :searching="searching" :has-result="hasResult" @on-page-no="onPageNo" @on-page-size="onPageSize">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="n-table-mark"></th>
            <th class="col-weight-10">{{ $t('label.domain') }}</th>
            <th class="col-weight-10">{{ $t('label.level') }}</th>
            <th class="col-weight-63">{{ $t('label.subject') }}</th>
            <th class="n-table-timestamp">{{ $t('label.issueTime') }}</th>
            <th class="action-detail">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>
              <i v-if="row.status === $enums.AdminOperatorNoticeStatus.PENDING" class="fa fa-envelope"></i>
              <i v-else class="far fa-envelope-open" />
            </td>
            <td v-enums:AdminNoticeDomain="row.domain"></td>
            <td v-enums:AdminNoticeLevel="row.level"></td>
            <td class="n-ellipsis" :title="row.subject">
              <span v-if="row.level === $enums.AdminNoticeLevel.INFO" class="n-success">{{ row.subject }}</span>
              <span v-if="row.level === $enums.AdminNoticeLevel.WARN" class="n-warning">{{ row.subject }}</span>
              <span v-if="row.level === $enums.AdminNoticeLevel.ERROR" class="n-error">{{ row.subject }}</span>
            </td>
            <td>{{ row.issueTime | datetime }}</td>
            <td class="n-button-box">
              <nb-custom type="info" text="detail" @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </div>
</template>

<script>
import OperatorView from './view';
import PaginateMixin from '@/mixins/paginate-mixin';
import ModalOperatorNotice from './ModalOperatorNotice';
import { history } from '@/api/operator/operator-operator-notice';

export default OperatorView.extend({
  name: 'OperatorOperatorNoticeHistory',
  mixins: [PaginateMixin],
  props: {
    condition: Object,
  },
  methods: {
    doSearch(params) {
      let p = { ...params, ...this.condition };
      return history(p);
    },
    parse(rows) {
      this.records = rows.records;
    },
    openModal(model = {}) {
      this.createModal(ModalOperatorNotice, { model, on: this });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  min-width: 850px;
}
</style>
