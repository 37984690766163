import { $fetch, $post } from '@/services/axios';

export function doRealtime(params) {
  return $fetch(`operator/operator/notices/latest`, params, false);
}

export function history(params) {
  return $fetch(`operator/operator/notices`, params);
}

export function update(model) {
  return $post(`operator/operator/notice`, model);
}

export function updates(notices) {
  return $post(`operator/operator/notices`, { notices: JSON.stringify(notices) });
}
