<template>
  <!-- prettier-ignore -->
  <index-content>
    <Tabs v-model="tab" name="operatorNotice" :animated="false">
      <TabPane tab="operatorNotice" name="realtime" :label="this.$t('label.realtime')" class="pt-2">
        <operator-operator-notice-realtime :condition="realtimeCondition" />
      </TabPane>
      <TabPane tab="operatorNotice" name="history" :label="this.$t('label.history')" class="pt-2">
        <operator-operator-notice-history :condition="historyCondition" />
      </TabPane>
    </Tabs>
  </index-content>
</template>

<script>
import OperatorView from './view';
import OperatorOperatorNoticeRealtime from './OperatorOperatorNoticeRealtime';
import OperatorOperatorNoticeHistory from './OperatorOperatorNoticeHistory';

export default OperatorView.extend({
  name: 'OperatorOperatorNotice',
  components: { OperatorOperatorNoticeRealtime, OperatorOperatorNoticeHistory },
  data() {
    return {
      tab: 'realtime',
      realtimeCondition: {
        companyId: null,
        domain: 0,
        type: 0,
        level: 0,
        status: 0,
      },
      historyCondition: {
        companyId: null,
        operatorId: this.operatorId,
        status: 0,
        from: this.oneWeekAgo,
        to: this.eod,
        domain: 0,
        type: 0,
        level: 0,
      },
    };
  },
  mounted() {
    this.realtimeCondition.companyId = this._companyId;
    this.historyCondition.companyId = this._companyId;
  },
});
</script>
