<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" detail>
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <n-field-id :value="`${model.id}`" label="id" disabled />
        <n-field-enum :value="model.type" enum-name="AdminNoticeType" label="type" disabled />
      </n-row-col2>
      <n-row-col2>
        <ns-company :value="model.companyId" disabled />
        <n-field-enum :value="model.level" enum-name="AdminNoticeLevel" label="level" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id :value="model.noticeId" label="noticeId" disabled />
        <n-field-enum :value="model.status" enum-name="AdminOperatorNoticeStatus" label="status" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id :value="model.sourceId" label="sourceId" disabled />
        <n-field-enum :value="model.domain" enum-name="AdminNoticeDomain" label="domain" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id :value="model.contextId === '0' ? null : model.contextId" label="contextId" disabled />
        <n-field-datetime :value="model.issueTime" label="issueTime" disabled />
      </n-row-col2>
      <n-row-col1>
        <n-field-text :value="`${model.subject}`" label="noticeSubject" disabled />
      </n-row-col1>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';

export default NModal.extend({
  name: 'ModalOperatorNotice',
  components: { NModal },
});
</script>
