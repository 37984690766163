<template>
  <!-- prettier-ignore -->
  <div>
    <search-condition>
      <n-field-enum v-model="condition.domain" enum-name="AdminNoticeDomain" label="domain" all-option @on-change="load" />
      <n-field-select v-model="condition.type" :options="noticeTypes" label="type" all-option @on-change="load" />
      <n-field-enum v-model="condition.level" enum-name="AdminNoticeLevel" label="level" all-option @on-change="load" />
      <n-field-enum v-model="condition.status" enum-name="AdminOperatorNoticeStatus" label="status" all-option @on-change="load" />
    </search-condition>
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="n-table-mark"></th>
            <th class="col-weight-10">{{ $t('label.domain') }}</th>
            <th class="col-weight-10">{{ $t('label.level') }}</th>
            <th class="col-weight-63">{{ $t('label.subject') }}</th>
            <th class="n-table-timestamp">{{ $t('label.issueTime') }}</th>
            <th class="action-detail">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in notices" :key="row.id" :class="selectedRows.find(v => v.id === row.id) ? 'n-row-selected' : ''" @click="clickRow(row)" @contextmenu.prevent="onContextMenu($event)">
            <td>
              <a v-if="row.status === PENDING" href="javascript:void(0)" @click.stop="markRead(row, $event)">
                <i class="fa fa-envelope" />
              </a>
              <i v-else class="far fa-envelope-open" />
            </td>
            <td v-enums:AdminNoticeDomain="row.domain"></td>
            <td v-enums:AdminNoticeLevel="row.level"></td>
            <td class="n-ellipsis" :title="row.subject">
              <span v-if="row.level === $enums.AdminNoticeLevel.INFO" class="n-success">{{ row.subject }}</span>
              <span v-if="row.level === $enums.AdminNoticeLevel.WARN" class="n-warning">{{ row.subject }}</span>
              <span v-if="row.level === $enums.AdminNoticeLevel.ERROR" class="n-error">{{ row.subject }}</span>
            </td>
            <td>{{ row.issueTime | datetime }}</td>
            <td class="n-button-box">
              <nb-custom type="info" text="detail" @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <n-context-menu :context-event="contextEvent" style="width: 120px;" @click="handleContextMenu">
      <DropdownItem name="accepted">
        {{ $t('label.markAsRead') }}
      </DropdownItem>
    </n-context-menu>
  </div>
</template>

<script>
import OperatorView from './view';
import ModalOperatorNotice from './ModalOperatorNotice';
import { doRealtime, update, updates } from '@/api/operator/operator-operator-notice';
import { NoticeChannel } from '@/services';
import { isEmpty } from '@/helpers/utils';
import { mapGetters } from 'vuex';
import _ from 'lodash-es';

export default OperatorView.extend({
  name: 'OperatorOperatorNoticeRealtime',
  props: {
    condition: Object,
  },
  data() {
    const {
      AdminOperatorNoticeStatus: { PENDING, ACCEPTED },
    } = this.$enums;
    return {
      preload: true,
      PENDING,
      ACCEPTED,
      selectedRows: [],
      contextEvent: {},
    };
  },
  computed: {
    ...mapGetters('menu', ['hasOperatorNoticeReadPrivilege']),
    notices() {
      const params = _.pickBy(
        { domain: this.condition.domain, type: this.condition.type, level: this.condition.level, status: this.condition.status },
        _.identity,
      );
      return _.filter(this.records, params);
    },
  },
  mounted() {
    if (this.hasOperatorNoticeReadPrivilege) NoticeChannel.subscribe(this.onRealtimeNotice);
  },
  beforeDestroy() {
    NoticeChannel.unsubscribe(this.onRealtimeNotice);
  },
  methods: {
    parse(rows) {
      this.records = rows;
    },
    doLoad() {
      let params = { companyId: this.condition.companyId };
      return doRealtime(params);
    },
    onRealtimeNotice(notice) {
      if (isEmpty(notice)) return;
      if (notice.status === this.ACCEPTED) {
        const record = _.find(this.records, r => r.id === notice.id);
        if (record) {
          record.status = this.ACCEPTED;
          this.selectedRows = _.remove(this.selectedRows, n => n.id !== notice.id);
        }
      } else if (notice.status === this.PENDING) this.records.unshift(notice);
      this.records.splice(50);
    },

    openModal(model = {}) {
      this.createModal(ModalOperatorNotice, { model, on: this });
    },

    markRead(model) {
      if (model.status !== this.PENDING) return;
      update({ ...model, status: this.ACCEPTED }).then(r => {
        if (this.isSuccess(r)) {
          this.load();
          this.selectedRows = _.remove(this.selectedRows, n => n.id !== model.id);
        } else this.showErrorMsg(r);
      });
    },

    clickRow(row) {
      if (row.status === this.ACCEPTED) return;
      let currentRow = this.selectedRows.find(v => v.id === row.id);
      if (currentRow !== undefined) {
        this.selectedRows = _.remove(this.selectedRows, function(n) {
          return n.id !== row.id;
        });
      } else this.selectedRows.push(row);
    },

    onContextMenu($event) {
      if (isEmpty(this.selectedRows)) return;
      this.contextEvent = $event;
    },

    handleContextMenu(name) {
      if (isEmpty(this.selectedRows)) return;
      let notices = [];
      _.forEach(this.selectedRows, notice => {
        const row = this.records.find(v => v.id === notice.id);
        notices.push({ ...row, status: this.ACCEPTED });
      });
      return updates(notices).then(r => {
        if (this.isSuccess(r)) {
          this.selectedRows = [];
          this.load();
        }
        return r;
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  min-width: 850px;

  tr.n-row-selected {
    background-color: #337ab7 !important;
    color: #ffffff !important;
  }
}
</style>
